import React from "react";

import Layout from "../../components/Layout";
import { Container, Divider } from "semantic-ui-react";

import {FeaturesTable as AuthenticationFeatures} from './authentication';
import {FeaturesTable as BrandingFeatures} from './branding';
import {FeaturesTable as ApiFeatures} from './apis';
import {FeaturesTable as ApplicationFeatures} from './apps';
// import {FeaturesTable as PasswordlessFeatures} from './passwordless';
import { FeaturesTable as MFAFeatures} from './mfa';


const FeaturesLayout = () => (
  <Layout title="Products" section="products">
    <Container>
      <Divider horizontal style={{margin: "6em 0 4em"}}>
        <span>Authentication</span>
      </Divider>
      <AuthenticationFeatures />

      {/* <Divider horizontal style={{margin: "6em 0 4em"}}>
        <span>Passwordless Authentication</span>
      </Divider>
      <PasswordlessFeatures /> */}

      <Divider horizontal style={{margin: "6em 0 4em"}}>
        <span>Multifactor Authentication</span>
      </Divider>
      <MFAFeatures />

      <Divider horizontal style={{margin: "6em 0 4em"}}>
        <span>Branding</span>
      </Divider>
      <BrandingFeatures/>
      <Divider horizontal style={{margin: "6em 0 4em"}}>
        <span>Applications</span>
      </Divider>
      <ApplicationFeatures />

      <Divider horizontal style={{margin: "6em 0 4em"}}>
        <span>API Access Management</span>
      </Divider>
      <ApiFeatures />

    </Container>
  </Layout>
);

export default FeaturesLayout;